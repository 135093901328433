<template>
  <router-view/>
</template>

<style src="./main.css">
</style>
<script>
export default {
  beforeMount(){
    window.Telegram.WebApp.expand();
    window.Telegram.WebApp.enableClosingConfirmation()
}
}
</script>
