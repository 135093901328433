<template>
  <div class="container">
    <div class="head">
      <div class="Home_page">
        <p class="h1">Добро пожаловать!</p>
        <img class="Home_page_logo" src="../img/Main-img.png" alt="">
        <p class="title">Чат-бот для сотрудников</p>
        
        
        <div class="MenuList">
          <div v-for="l in MenuList" :key="l">
            <div class="MenuList_element main-btn" @click="rout(l.rout + '?user_id=' + token)">
              <img :src="l.img" alt="">
              <p>{{l.title}}</p>
            </div>
          </div>
        </div>
       
       
        <!-- <img src="../img/Main-logo.svg" alt=""> -->
      </div>
    </div>
    <div class="main"></div>
    <div class="footer"></div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      token: window.location.hash.split('=')[1],
      MenuList: [
        {
          img: require('../img/icon/Onboarding_icon.svg'),
          title:'Онбординг',
          rout: '/Onboarding'
        },
        {
          img: require('../img/icon/Chat_icon.svg'),
          title:'Обратная связь',
          rout: '/Chat'
        },
        {
          img: require('../img/icon/Organizational_icon.svg'),
          title:'Оргструктура',
          rout: '/Organizational'
        },
        {
          img: require('../img/icon/Team_icon.svg'),
          title:'Моя команда',
          rout: '/Team'
        },
      ]
    }
  },
  methods:  {
    rout(r) {
        this.$router.push(r);
    },
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');
.h1 {
  font-size: 20px;
  font-weight: 700;
}
.title {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 19.6px;
}
.Home_page_logo {
  width: 115px;
}
.Home_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.MenuList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(100% - 50px);
  margin: 0 25px;
}
.MenuList_element {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

</style>
